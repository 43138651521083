import { gql } from '@apollo/client'

// common task fields
const commonTaskFields = `
  id
  title
  internalName
  description
  taskType
  frequencyType
  locationHierarchyId
  rrule
  mediaUrl
  taskCompleted
  docusignTemplateId
  templateTaskId
  isTemplatePublished
  templateCategory
  multipleOptionsId
  dueDate
  eligibleFrom
  hideFromCustomer
  showNotApplicableOption
  emailFrom {
    label
    value
    type
  }
  dependsOnTaskId
  ccTo {
    label
    value
    type
  }
  users {
    id
    email
  }
  userRoles {
    userId
    templateRoleId
  }
  templateRoles {
    id
    name
  }
`

// fragment for schema tasks: __typename "Task"
export const FRAGMENT_TASK_FIELDS = gql`
  fragment TaskFields on Task {
    ${commonTaskFields}
    hasOwnDescription
    titleRaw
    descriptionRaw
    lastEvent {
      eventType
      percentage
      score
      totalScore
      completedAt
      scheduledAt
      createdAt
      emailEvents {
        emailType
        eventType
        timestamp
      }
      isProcessing
    }
    lastError {
      error
      createdAt
    }
    sendAtTimezone
    sendAtTime
    slackEventType
    videoUrl
    emailHeaderUrl
    emailFooterUrl
    emailBackgroundColor
    emailBorderColor
    centralizeEmail
    tasksOverrideValues {
      overrideValue {
        value
        taskPropertyName
      }
    }
  }
`

const FRAGMENT_TEMPLATE_TASK_DATE_SETTINGS = gql`
  fragment TemplateTaskDateSettingsFields on TemplateTaskDateSetting {
    dateType {
      id
      name
      baseDateType {
        id
        name
      }
    }
    days
    relation
    useBusinessDays
  }
`

export const FRAGMENT_TEMPLATE_TASK_FIELDS = gql`
  fragment TemplateTaskFields on TemplateTask {
    id
    internalName
    title
    titleRaw
    description
    descriptionRaw
    taskType
    mediaUrl
    isTemplatePublished
    showNotApplicableOption
    inactive
    preBoarding
    offboarding
    important
    isDelegable
    estimateInMins
    allowMultiSelect
    slackChannelsIds
    slackEventType
    videoUrl
    emailHeaderUrl
    emailFooterUrl
    emailBackgroundColor
    emailBorderColor
    centralizeEmail
    emailFooterIconId
    emailHeaderIconId
    emailSendAt
    emailTimezone
    dependsOnTemplateTaskId
    doceboCourseId
    iconLibraryId
    docusignTemplateId
    multipleOptionsId
    templateCategoryId
    templateCategory {
      id
      ancestry
      category
      internalCategory
      inactive
    }
    templateRoles {
      id
      name
    }
    taxonomyTerms {
      id
      title
      key
      taxonomyId
    }
    excludedTaxonomyTerms {
      id
      title
      taxonomyId
    }
    templateTaskDateSetting {
      ...TemplateTaskDateSettingsFields
    }
    assignDate {
      ...TemplateTaskDateSettingsFields
    }
    notifyOnComplete {
      label
      value
      type
    }
    notifyOnCantComplete {
      label
      value
      type
    }
    ccTo {
      label
      value
      type
    }
    emailFrom {
      label
      value
      type
    }
    questions {
      id
      title
      required
      description
      explanation
      order
      quizType
      updatedAt
      options {
        id
        order
        title
        description
        score
        correct
      }
    }
    contentItemId
    contentItem {
      id
      title
      description
      descriptionRaw
      contentItemsSectionId
    }
    scormCourse {
      id
      title
    }
    templateTaskCalendarEvents {
      calendarEventId
      calendarOwnerEmail
      calendarId
      __typename
    }
    calendarEventOrganizerTemplateRole {
      id
      name
      __typename
    }
    calendarConferenceOptions {
      security
      registrationRequired
      joinBeforeHost
      autoRecording
      muteUponEntry
      questionAndAnswer
      alternativeHosts
      __typename
    }
    calendarId
    calendarEventType
    calendarEventStartTime
    calendarEventDuration
    calendarEventTimezone
    calendarEventSmartScheduling
    calendarEventFacilitators
    calendarEventConferenceTool
    calendarEventLocation
    calendarOwnerEmail
    zoomOwnerEmail
    owner {
      id
      fullName
      email
    }
    excludedTaxonomyTerms {
      id
      taxonomyId
    }
    sendOverdueEmail
    templateTaskOverrideRules {
      id
    }
  }
  ${FRAGMENT_TEMPLATE_TASK_DATE_SETTINGS}
`

export const FRAGMENT_USER_FIELDS = gql`
  fragment UserFields on User {
    id
    email
    personalEmail
    firstName
    lastName
    fullName
    isConfidentialNewHire
    isTemp
    startDate
    homeAddress
    phoneNumber
    mainClient {
      id
      name
    }
    manager {
      id
      email
      firstName
      lastName
      fullName
    }
    isMainClient
    isMainClientAdmin: isAdmin
    isAdmin: isAdminByClient
    taxonomyTerms: taxonomyTermsByClient {
      id
      taxonomyId
    }
    taxonomyTermsAdmins {
      id
      taxonomyId
    }
    role {
      id
      name
    }
    welcomeEmailSentAt
    personalWelcomeEmailSentAt
    upn
    externalId
    terminationDate
    externalUpdateDisabled
    userDates {
      date
      dateType {
        id
      }
    }
    customFields
    isTerminated
    preboarding
    customFields
    avatarUrl
    excludeSso
  }
`

export const FRAGMENT_USER_DATES_ROLES_FIELDS = gql`
  fragment UserDatesAndRolesFields on User {
    userDates {
      date
      dateType {
        id
      }
    }
    userTemplateRolesAssignees {
      templateRole {
        id
      }
      user {
        id
        fullName
        email
      }
      assignee {
        id
        fullName
        email
      }
    }
  }
`

export const FRAGMENT_USER_OWNER_FIELDS = gql`
  fragment UserOwnerFields on User {
    id
    firstName
    lastName
    fullName
    email
    workEmail
    personalEmail
    taxonomyTerms {
      title
      id
      taxonomy {
        name
        slug
      }
    }
    userDates {
      id
      date
      dateType {
        id
        name
      }
    }
  }
`

export const FRAGMENT_USER_AUTOCOMPLETE_FIELDS = gql`
  fragment UserAutocompleteFields on User {
    id
    email
    firstName
    lastName
    fullName
  }
`

export const FRAGMENT_PAGINATION_META_FIELDS = gql`
  fragment PaginationMetaFields on PaginationMeta {
    page
    perPage
    totalPages
    totalCount
    nextPage
    prevPage
  }
`

export const FRAGMENT_EXTERNAL_USER_AUTOCOMPLETE_WITH_ASSIGNMENTS_FIELDS = gql`
  fragment UserAutocompleteFields on ExternalUser {
    email
    firstName
    lastName
    fullName
    externalId
    userId
    userTemplateRolesAssignments {
      id
      templateRoleId
    }
  }
`

export const FRAGMENT_TEMPLATE_CATEGORY_FOLDER = gql`
  fragment TemplateCategoryFolderFields on TemplateCategoryFolder {
    id
    title
    position
    owner {
      ...UserOwnerFields
    }
  }
  ${FRAGMENT_USER_OWNER_FIELDS}
`
